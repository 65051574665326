import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { NavItem } from './components';
import Logotype from '../../../../components/Logotype';

interface Props {
  colorInvert?: boolean;
  onClick?: (id: string) => void;
  menuItem: { id: string; text: string }[];
}

const Topbar = ({
  colorInvert = false,
  onClick = () => {},
  menuItem,
}: Props): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="abocadot"
        width={{ xs: 100, md: 120 }}
      >
        {mode === 'light' && !colorInvert ? (
          <Logotype fill="black" height={24} green />
        ) : (
          <Logotype fill="white" height={24} green />
        )}
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        {menuItem.map((item, index) => (
          <Box key={item.id} marginLeft={index > 0 ? 4 : 0}>
            <NavItem
              title={item.text}
              id={item.id}
              colorInvert={colorInvert}
              onClick={onClick}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Topbar;
