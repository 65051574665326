import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import syamamotoProfileImage from 'assets/images/members/syamamoto.jpeg';
import rtaguchiProfileImage from 'assets/images/members/rtaguchi.jpeg';
import karaiProfileImage from 'assets/images/members/karai.jpeg';
import ksukenobeProfileImage from 'assets/images/members/ksukenobe.jpeg';
import tadachiProfileImage from 'assets/images/members/tadachi-square.jpeg';

const Team = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          {t('Our team')}
        </Typography>
        <Typography fontWeight={700} variant={'h3'} align={'center'}>
          {t('5 Ninja for New Era')}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {[
          {
            name: 'Shunichiro Yamamoto',
            title: 'R&TT - Studio Senior Manager',
            avatar: syamamotoProfileImage,
          },
          {
            name: 'Rintaro Taguchi',
            title: 'R&TT - Senior Manager',
            avatar: rtaguchiProfileImage,
          },
          {
            name: 'Keita Arai',
            title: 'R&TT - Studio Senior Lead',
            avatar: karaiProfileImage,
          },
          {
            name: 'Kaori Sukenobe',
            title: 'R&TT - Studio Senior Lead',
            avatar: ksukenobeProfileImage,
          },
          {
            name: 'Takanori Adachi',
            title: 'R&TT - Studio Lead',
            avatar: tadachiProfileImage,
          },
        ].map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i}>
            <Box
              component={Card}
              boxShadow={2}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <CardContent>
                <Box
                  component={Avatar}
                  src={item.avatar}
                  height={80}
                  width={80}
                />
                <Box marginTop={4}>
                  <ListItemText primary={item.name} secondary={item.title} />
                  <Box marginTop={2}>
                    <IconButton
                      size={'small'}
                      sx={{
                        color:
                          theme.palette.mode === 'light'
                            ? '#4267B2'
                            : '#898F9C',
                      }}
                    >
                      <FacebookIcon />
                    </IconButton>
                    <IconButton
                      size={'small'}
                      sx={{
                        color:
                          theme.palette.mode === 'light'
                            ? '#1DA1F2'
                            : '#898F9C',
                      }}
                    >
                      <TwitterIcon />
                    </IconButton>
                    <IconButton
                      size={'small'}
                      sx={{
                        color:
                          theme.palette.mode === 'light' ? 'black' : '#898F9C',
                      }}
                    >
                      <GitHubIcon />
                    </IconButton>
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Team;
