import React from 'react';
import { Text, useTheme, View } from '@aws-amplify/ui-react';

export default function Footer() {
  const { tokens } = useTheme();

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Text color={`${tokens.colors.neutral['40']}`}>&copy; 2022 avocadot</Text>
    </View>
  );
}
