import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useTranslation } from 'react-i18next';

import PhoneSkeletonIllustration from 'svg/illustrations/PhoneSkeleton';
import LaptopSkeletonIllustration from 'svg/illustrations/LaptopSkeleton';

import onlineDoctorsAppLightImage from 'assets/images/features/online-doctors-app-light.png';
import onlineDoctorsAppDarkImage from 'assets/images/features/online-doctors-app-dark.png';
import onlineDoctorsDashboardLightImage from 'assets/images/features/online-doctors-dashboard-light.png';
import onlineDoctorsDashboardDarkImage from 'assets/images/features/online-doctors-dashboard-dark.png';

const Features = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          {t('Our Demo Applications')}
        </Typography>
        <Typography
          variant={'h3'}
          sx={{ fontWeight: 700 }}
          align={'center'}
          gutterBottom
        >
          {t('Experience The "New"')}
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          align={'center'}
        >
          {t('Please click on the demo you are interested in.')}
        </Typography>
      </Box>
      <Grid
        container
        data-aos="fade-up"
        data-aos-delay={0} // i * 100
        data-aos-offset={100}
        data-aos-duration={600}
        spacing={4}
        mb={4}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              maxWidth: 450,
              position: 'relative',
              marginX: 'auto',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                borderRadius: '2.75rem',
                boxShadow: 1,
                width: '75% !important',
                marginX: 'auto',
              }}
            >
              <Box>
                <Box
                  position={'relative'}
                  zIndex={2}
                  maxWidth={1}
                  height={'auto'}
                  sx={{ verticalAlign: 'middle' }}
                >
                  <PhoneSkeletonIllustration />
                </Box>
                <Box
                  position={'absolute'}
                  top={'2.4%'}
                  left={'4%'}
                  width={'92.4%'}
                  height={'96%'}
                  sx={{
                    '& .lazy-load-image-loaded': {
                      height: 1,
                      width: 1,
                    },
                  }}
                >
                  <Box
                    component={LazyLoadImage}
                    effect="blur"
                    src={
                      theme.palette.mode === 'light'
                        ? onlineDoctorsAppLightImage
                        : onlineDoctorsAppDarkImage
                    }
                    alt="Image Description"
                    width={1}
                    height={1}
                    sx={{
                      objectFit: 'cover',
                      borderRadius: '2.5rem',
                      filter:
                        theme.palette.mode === 'dark'
                          ? 'brightness(0.7)'
                          : 'none',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Box marginBottom={2}>
              <Typography
                variant={'h4'}
                color="text.primary"
                sx={{
                  fontWeight: 700,
                }}
              >
                Online Doctors App &amp; Dashboard
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography component="p" color="text.secondary">
                {t(
                  'There is a growing focus on patient-centeredness and patient engagement, as evidenced by survey results showing that the biggest outcomes from digital transformation in healthcare are patient satisfaction and improved patient relationships. We are responding to these trends by quickly delivering a superior patient experience.',
                )}
              </Typography>
            </Box>
            <Button
              size={'large'}
              sx={{ marginTop: 2 }}
              variant={'contained'}
              endIcon={<OpenInNewOutlinedIcon />}
              onClick={() =>
                window.open('https://online-doctors-app.avocadot.es/', '_blank')
              }
            >
              {t('App Demo')}
            </Button>

            <Button
              size={'large'}
              sx={{ marginTop: 2, marginLeft: 2 }}
              variant={'contained'}
              endIcon={<OpenInNewOutlinedIcon />}
              onClick={() =>
                window.open(
                  'https://online-doctors-dashboard.avocadot.es/',
                  '_blank',
                )
              }
            >
              {t('Dashboard Demo')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        data-aos="fade-up"
        data-aos-delay={100}
        data-aos-offset={100}
        data-aos-duration={600}
        spacing={4}
      >
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Box marginBottom={3}>
              <Typography component="p" color="text.secondary">
                {t(
                  'Here are some technical highlights of our MVP (Minimum Viable Product):',
                )}
              </Typography>
            </Box>
            <Grid container spacing={1}>
              {[
                t(
                  'Built as modern web (React, TypeScript, Material UI, GraphQL)',
                ),
                t('Adopt Serverless architecture'),
                t('Practice IaC (Infrastructure as Code)'),
                t('Video multipart upload & video streaming'),
              ].map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.primary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box width={1}>
            <Box
              sx={{
                position: 'relative',
                marginX: 'auto',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  marginX: 'auto',
                }}
              >
                <Box>
                  <Box
                    position={'relative'}
                    zIndex={2}
                    maxWidth={1}
                    height={'auto'}
                    sx={{ verticalAlign: 'middle' }}
                  >
                    <LaptopSkeletonIllustration />
                  </Box>
                  <Box
                    position={'absolute'}
                    top={'8.4%'}
                    left={'12%'}
                    width={'76%'}
                    height={'83%'}
                    border={`1px solid ${theme.palette.alternate.dark}`}
                    zIndex={3}
                    sx={{
                      '& .lazy-load-image-loaded': {
                        height: 1,
                        width: 1,
                      },
                    }}
                  >
                    <Box
                      component={LazyLoadImage}
                      effect="blur"
                      src={
                        theme.palette.mode === 'light'
                          ? onlineDoctorsDashboardLightImage
                          : onlineDoctorsDashboardDarkImage
                      }
                      alt="Image Description"
                      width={1}
                      height={1}
                      sx={{
                        objectFit: 'cover',
                        filter:
                          theme.palette.mode === 'dark'
                            ? 'brightness(0.7)'
                            : 'none',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
