import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import GTranslateOutlinedIcon from '@mui/icons-material/GTranslateOutlined';
import { alpha, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const TranslationToggler = (): JSX.Element => {
  const theme = useTheme();
  const { i18n } = useTranslation();

  useEffect(() => {
    try {
      const locale: string | null =
        window.localStorage.getItem('avocadot.locale');

      if (locale) {
        i18n.changeLanguage(locale);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleClick = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('ja');
      window.localStorage.setItem('avocadot.locale', 'ja');
    } else {
      i18n.changeLanguage('en');
      window.localStorage.setItem('avocadot.locale', 'en');
    }
  };

  return (
    <Button
      variant={'outlined'}
      onClick={handleClick}
      aria-label="Logout"
      color={'secondary'}
      sx={{
        borderRadius: 2,
        minWidth: 'auto',
        padding: 0.5,
        borderColor: alpha(theme.palette.secondary.main, 0.2),
      }}
    >
      <GTranslateOutlinedIcon sx={{ width: 20, height: 20 }} />
    </Button>
  );
};

export default TranslationToggler;
