import React from 'react';
import { Heading, Text, useTheme } from '@aws-amplify/ui-react';

export default function SignInHeader() {
  const { tokens } = useTheme();

  return (
    <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
      Sign In
      <Text
        fontSize={tokens.fontSizes.medium}
        color={tokens.colors.neutral['80']}
        paddingTop={tokens.space.xxxs}
      ></Text>
    </Heading>
  );
}
