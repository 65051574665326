import React, { useEffect } from 'react';
import Typed from 'react-typed';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

import Container from 'components/Container';
import backgroundImage from 'assets/images/avocado-bg.jpeg';

interface Props {
  onClick?: (id: string) => void;
}

const Hero = ({ onClick = () => {} }: Props): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll('.jarallax');
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import('jarallax');
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });

  return (
    <Box
      position={'relative'}
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginTop: -13,
        paddingTop: 13,
        '&:after': {
          position: 'absolute',
          content: '" "',
          width: '100%',
          height: '100%',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          background: '#161c2d',
          opacity: 0.6,
        },
      }}
    >
      <Container position={'relative'} zIndex={2}>
        <Box marginBottom={2}>
          <Typography
            variant="h2"
            color="text.primary"
            sx={{
              fontWeight: 700,
              color: theme.palette.common.white,
            }}
          >
            New technology
            <br />
            makes you{' '}
            <Typography
              color={'primary'}
              component={'span'}
              variant={'inherit'}
              sx={{
                background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              <Typed strings={['Happy.']} typeSpeed={140} loop={true} />
            </Typography>
          </Typography>
        </Box>
        <Box marginBottom={3}>
          <Typography
            variant="h5"
            component="p"
            color="text.secondary"
            sx={{
              fontWeight: 400,
              color: theme.palette.common.white,
              [theme.breakpoints.up('md')]: { width: '64%' },
            }}
          >
            {t(
              'We provide value for new era by combining prototypes using digital technology and ideas from business-savvy professionals.',
            )}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'stretched', sm: 'flex-start' }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth={isMd ? false : true}
            onClick={() => onClick('features')}
          >
            {t('Experience Now')}
          </Button>
        </Box>
      </Container>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        width={1}
        marginBottom={-1}
        position={'relative'}
        zIndex={2}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

export default Hero;
