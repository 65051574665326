import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
    },
  },
  ja: {
    translation: {
      'We provide value for new era by combining prototypes using digital technology and ideas from business-savvy professionals.':
        'デジタル技術を駆使したプロトタイプと、ビジネスに精通したプロフェッショナルのアイデアを組み合わせて、新しい時代の価値を提供します。',
      'Experience Now': '今すぐ体験する',
      Showcase: 'ショーケース',
      'Our Demo Applications': '私たちのデモアプリケーション',
      'Experience The "New"': '「新しい」を体験する',
      'Please click on the demo you are interested in.':
        'ご覧になりたいデモをクリックしてください。',
      'There is a growing focus on patient-centeredness and patient engagement, as evidenced by survey results showing that the biggest outcomes from digital transformation in healthcare are patient satisfaction and improved patient relationships. We are responding to these trends by quickly delivering a superior patient experience.':
        'ヘルスケア分野におけるデジタルトランスフォーメーションから達成したい最大の成果は、患者満足度と患者との関係性向上というアンケート結果からも分かるように、患者中心アプローチや患者エンゲージメントへの注目が高まっています。わたしたちは、優れた患者エクスペリエンスをクイックに提供することで、これらのトレンドに応えていきます。',
      'Here are some technical highlights of our MVP (Minimum Viable Product):':
        'わたしたちのMVP（Minimum Viable Product）の技術的ハイライトはこちら：',
      'Built as modern web (React, TypeScript, Material UI, GraphQL)':
        'モダンWebとして構築（React, TypeScript, Material UI, GraphQL）',
      'Serverless architecture adopted': 'サーバーレスアーキテクチャを採用',
      'Practice IaC (Infrastructure as Code)':
        'IaC（Infrastructure as Code）を実践',
      'Video multipart upload & video streaming':
        '動画マルチパートアップロード & 動画ストリーミング再生',
      'Live Preview': 'デモアプリ',
      'App Demo': 'アプリデモ',
      'Dashboard Demo': 'ダッシュボードデモ',
      'About us': 'チーム概要',
      'WHAT WE DO?': 'Avocadot とは？',
      'Inspire the digital with tangible prototypes': '動くもので命を吹き込む',
      'We work together with our clients to drive digital transformation by inspiring digital concepts with tangible prototypes, and by completing not only the digital conception, but also the digital implementation.':
        'わたしたちは、動くものファーストでデジタル構想に手触り感をトッピングし、構想を構想だけで終わらせずにデジタル実装へと確実につなげ、クライアントとともにデジタル変革を推進します。',
      'Our Mission': '私たちのミッション',
      'Provide value for a new era by combining prototype using digital technology and realistic ideas.':
        'デジタル技術を駆使した試作品と現実的なアイデアを組み合わせて、新しい時代の価値を提供します。',
      'Our Value': '私たちの価値観',
      'Co-creating value for a new era through internal and external collaboration and a prototyping-first style.':
        '社内外のコラボレーションとプロトタイピング・ファーストのスタイルで、新しい時代の価値を共創します。',
      'Our Style': '私たちのスタイル',
      'Skill sets of business and technology as well as high-demensional integartion of mindsets and styles for a new era.':
        'ビジネスとテクノロジーのスキルセット、そして新しい時代のためのマインドセットとスタイルを高次元で融合します。',
      Members: 'メンバー',
      'Our team': 'チームメンバー',
      '5 Ninja for New Era': '高い専門性を持つ5人の "Ninja"',
      Contact: 'お問い合わせ',
      'Contact us': 'お問い合わせ',
      Email: 'メールアドレス',
      Address: '住所',
      'Design Guidelines': 'デザインガイドライン',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  keySeparator: '>>',
  nsSeparator: '||',
});
