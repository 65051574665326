import React from 'react';
import { View, useTheme } from '@aws-amplify/ui-react';
import Logotype from '../../components/Logotype';

export default function Header() {
  const { tokens } = useTheme();

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Logotype fill="white" width={200} height={40} green />
    </View>
  );
}
