/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Box, Typography } from '@mui/material';
import ColorTheme from './ColorTheme';
import 'assets/styles/deloitte.css';

interface Color {
  name: string;
  variable: string;
  bordered?: boolean;
  inversed?: boolean;
  xs?: any;
}

interface Pallete {
  name: string;
  colors: Array<Color>;
}

const themePowerPoint: Array<Pallete> = [
  {
    name: 'Basic',
    colors: [
      { name: 'White', variable: '--deloitte-white', bordered: true },
      { name: 'Black', variable: '--deloitte-black', inversed: true },
    ],
  },
  {
    name: 'Highlight',
    colors: [
      { name: 'Deloitte Green', variable: '--deloitte-deloitte-green' },
      { name: 'Green 4', variable: '--deloitte-green-4' },
      { name: 'Accessible Green', variable: '--deloitte-accessible-green' },
      { name: 'Green 6', variable: '--deloitte-green-6', inversed: true },
      { name: 'Accessible Teal', variable: '--deloitte-accessible-teal' },
      { name: 'Accessible Blue', variable: '--deloitte-accessible-blue' },
    ],
  },
  {
    name: 'Color Pallete',
    colors: [
      { name: 'Green 1', variable: '--deloitte-green-1' },
      { name: 'Green 2', variable: '--deloitte-green-2' },
      { name: 'Deloitte Green', variable: '--deloitte-deloitte-green' },
      { name: 'Green 4', variable: '--deloitte-green-4' },
      { name: 'Green 5', variable: '--deloitte-green-5' },
      { name: 'Green 6', variable: '--deloitte-green-6', inversed: true },
      { name: 'Green 7', variable: '--deloitte-green-7', inversed: true },
      { name: 'Blue 1', variable: '--deloitte-blue-1' },
      { name: 'Blue 2', variable: '--deloitte-blue-2' },
      { name: 'Blue 3', variable: '--deloitte-blue-3' },
      { name: 'Blue 4', variable: '--deloitte-blue-4', inversed: true },
      { name: 'Blue 5', variable: '--deloitte-blue-5', inversed: true },
      { name: 'Blue 6', variable: '--deloitte-blue-6', inversed: true },
      { name: 'Blue 7', variable: '--deloitte-blue-7', inversed: true },
      { name: 'Teal 1', variable: '--deloitte-teal-1' },
      { name: 'Teal 2', variable: '--deloitte-teal-2' },
      { name: 'Teal 3', variable: '--deloitte-teal-3' },
      { name: 'Teal 4', variable: '--deloitte-teal-4' },
      { name: 'Teal 5', variable: '--deloitte-teal-5', inversed: true },
      { name: 'Teal 6', variable: '--deloitte-teal-6', inversed: true },
      { name: 'Teal 7', variable: '--deloitte-teal-7', inversed: true },
      { name: 'Cool Gray 2', variable: '--deloitte-cool-gray-2' },
      { name: 'Cool Gray 4', variable: '--deloitte-cool-gray-4' },
      { name: 'Cool Gray 6', variable: '--deloitte-cool-gray-6' },
      { name: 'Cool Gray 7', variable: '--deloitte-cool-gray-7' },
      {
        name: 'Cool Gray 9',
        variable: '--deloitte-cool-gray-9',
        inversed: true,
      },
      {
        name: 'Cool Gray 10',
        variable: '--deloitte-cool-gray-10',
        inversed: true,
      },
      {
        name: 'Cool Gray 11',
        variable: '--deloitte-cool-gray-11',
        inversed: true,
      },
    ],
  },
  {
    name: 'Limited Use',
    colors: [
      { name: 'Red', variable: '--deloitte-red', inversed: true },
      { name: 'Orange', variable: '--deloitte-orange' },
      { name: 'Yellow', variable: '--deloitte-yellow' },
      { name: 'Bright Green', variable: '--deloitte-bright-green' },
      { name: 'Bright Teal', variable: '--deloitte-bright-teal' },
      { name: 'Bright Blue', variable: '--deloitte-bright-blue' },
    ],
  },
];

const theme50thAnniversary: Array<Pallete> = [
  {
    name: 'Primary Pallete',
    colors: [
      { name: 'White', variable: '--deloitte-white', bordered: true, xs: 4 },
      { name: 'Black', variable: '--deloitte-black', inversed: true, xs: 4 },
      {
        name: 'Deloitte Green',
        variable: '--deloitte-deloitte-green',
        inversed: true,
        xs: 4,
      },
      { name: 'Green 2', variable: '--deloitte-green-2', inversed: true },
      { name: 'Green 4', variable: '--deloitte-green-4', inversed: true },
      { name: 'Green 6', variable: '--deloitte-green-6', inversed: true },
      { name: 'Green 7', variable: '--deloitte-green-7', inversed: true },
      { name: 'Teal 5', variable: '--deloitte-teal-5', inversed: true },
      { name: 'Blue 2', variable: '--deloitte-blue-2', inversed: true },
      { name: 'Blue 3', variable: '--deloitte-blue-3', inversed: true },
      { name: 'Blue 4', variable: '--deloitte-blue-4', inversed: true },
      { name: 'Blue 6', variable: '--deloitte-blue-6', inversed: true },
      { name: 'Cool Gray 2', variable: '--deloitte-cool-gray-2' },
      { name: 'Cool Gray 4', variable: '--deloitte-cool-gray-4' },
      { name: 'Cool Gray 7', variable: '--deloitte-cool-gray-7' },
      {
        name: 'Cool Gray 9',
        variable: '--deloitte-cool-gray-9',
        inversed: true,
      },
      {
        name: 'Cool Gray 11',
        variable: '--deloitte-cool-gray-11',
        inversed: true,
      },
    ],
  },
  {
    name: 'Secondary Pallete',
    colors: [
      { name: 'Green 1', variable: '--deloitte-green-1' },
      { name: 'Green 5', variable: '--deloitte-green-5' },
      { name: 'Teal 1', variable: '--deloitte-teal-1' },
      { name: 'Teal 2', variable: '--deloitte-teal-2' },
      { name: 'Teal 3', variable: '--deloitte-teal-3' },
      { name: 'Teal 4', variable: '--deloitte-teal-4' },
      { name: 'Teal 6', variable: '--deloitte-teal-6', inversed: true },
      { name: 'Teal 7', variable: '--deloitte-teal-7', inversed: true },
      { name: 'Blue 1', variable: '--deloitte-blue-1' },
      { name: 'Blue 5', variable: '--deloitte-blue-5', inversed: true },
      { name: 'Blue 7', variable: '--deloitte-blue-7', inversed: true },
      {
        name: 'Cool Gray 6',
        variable: '--deloitte-cool-gray-6',
        inversed: true,
      },
      {
        name: 'Cool Gray 10',
        variable: '--deloitte-cool-gray-10',
        inversed: true,
      },
    ],
  },
];

const Colors = (): JSX.Element => (
  <Box marginBottom={4}>
    <Typography
      sx={{
        textTransform: 'uppercase',
        fontWeight: 'medium',
      }}
      gutterBottom
      color={`var(--deloitte-deloitte-green)`}
    >
      Colors
    </Typography>
    <Typography
      variant="h4"
      sx={{
        fontWeight: 700,
      }}
    >
      PowerPoint Template
    </Typography>
    <ColorTheme columns={7} defaultXs={1} theme={themePowerPoint} />
    <Typography
      variant="h4"
      marginTop={8}
      sx={{
        fontWeight: 700,
      }}
    >
      50th Anniversary
    </Typography>
    <ColorTheme columns={12} defaultXs={3} theme={theme50thAnniversary} />
  </Box>
);

export default Colors;
