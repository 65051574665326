import React, { useRef } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Box, Button, Typography } from '@mui/material';

const codeString = `:root {
  --deloitte-white: #fff;
  --deloitte-black: #000;
  --deloitte-deloitte-green: #86bc25;
  --deloitte-green-4: #43b02a;
  --deloitte-accessible-green: #26890d;
  --deloitte-green-6: #046a38;
  --deloitte-accessible-teal: #0d8390;
  --deloitte-accessible-blue: #007cb0;
  --deloitte-green-1: #e3e48d;
  --deloitte-green-2: #c4d600;
  --deloitte-green-5: #009a44;
  --deloitte-green-7: #2c5234;
  --deloitte-blue-1: #a0dcff;
  --deloitte-blue-2: #62b5e5;
  --deloitte-blue-3: #00a3e0;
  --deloitte-blue-4: #0076a8;
  --deloitte-blue-5: #005587;
  --deloitte-blue-6: #012169;
  --deloitte-blue-7: #041e42;
  --deloitte-teal-1: #ddefe8;
  --deloitte-teal-2: #9dd4cf;
  --deloitte-teal-3: #6fc2b4;
  --deloitte-teal-4: #00abab;
  --deloitte-teal-5: #0097a9;
  --deloitte-teal-6: #007680;
  --deloitte-teal-7: #004f59;
  --deloitte-cool-gray-2: #d0d0ce;
  --deloitte-cool-gray-4: #bbbcbc;
  --deloitte-cool-gray-6: #a7a8aa;
  --deloitte-cool-gray-7: #97999b;
  --deloitte-cool-gray-9: #75787b;
  --deloitte-cool-gray-10: #63666a;
  --deloitte-cool-gray-11: #53565a;
  --deloitte-red: #da291c;
  --deloitte-orange: #ed8b00;
  --deloitte-yellow: #ffcd00;
  --deloitte-bright-green: #0df200;
  --deloitte-bright-teal: #3efac5;
  --deloitte-bright-blue: #33f0ff;
}`;

const CSSVariables = (): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement>();

  const handleClick = () => {
    navigator.clipboard.writeText(codeString);
    buttonRef.current.textContent = 'Copied';
    setTimeout(() => {
      buttonRef.current.textContent = 'Copy';
    }, 3000);
  };

  return (
    <Box marginBottom={4}>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'medium',
        }}
        gutterBottom
        color={`var(--deloitte-deloitte-green)`}
      >
        CSS Variables
      </Typography>
      <Box textAlign="right" mt={4} mb={-8} mr={1}>
        <Button variant="outlined" ref={buttonRef} onClick={handleClick}>
          Copy
        </Button>
      </Box>
      <SyntaxHighlighter language="css" style={a11yDark}>
        {codeString}
      </SyntaxHighlighter>
    </Box>
  );
};

export default CSSVariables;
