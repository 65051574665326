import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import AvocadotMain from 'layouts/AvocadotMain';
import Container from 'components/Container';
import { Colors, CSSVariables } from './components';

interface MenuItem {
  id: string;
  text: string;
  component: JSX.Element;
  transition?: boolean;
}

const mock: Array<MenuItem> = [
  {
    id: 'colors',
    text: 'Colors',
    component: (
      <Container>
        <Colors />
      </Container>
    ),
  },
  {
    id: 'css-variables',
    text: 'CSS Variables',
    component: (
      <Container>
        <CSSVariables />
      </Container>
    ),
  },
];

const DesignGuidelines = (): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();
  const itemsRef = React.useRef<{ id: string; ref: HTMLElement }[]>([]);
  const scrollToSection = React.useCallback((id) => {
    const targetRefs = itemsRef!.current.filter((item) => item.id === id);
    targetRefs.pop().ref.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  const onClick = (id: string) => {
    if (mock.find((menuItem) => menuItem.id === id).transition) {
      history.push('/' + id);
    } else {
      scrollToSection(id);
    }
  };

  return (
    <AvocadotMain
      colorInvert={false}
      onClick={onClick}
      menuItem={mock.map((item) => ({ id: item.id, text: item.text }))}
    >
      <Box marginTop={-13} paddingTop={13}>
        {mock.map(
          (item, index) =>
            !item.transition && (
              <div
                key={item.id}
                ref={(elem) =>
                  itemsRef.current.push({ id: mock[index].id, ref: elem })
                }
              >
                <Box
                  bgcolor={
                    index % 2 === 0
                      ? theme.palette.background.paper
                      : theme.palette.alternate.main
                  }
                >
                  {item.component}
                </Box>
              </div>
            ),
        )}
      </Box>
    </AvocadotMain>
  );
};

export default DesignGuidelines;
