/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import ourMissionImage from 'assets/images/about-us/our-mission-640x400.jpeg';
import ourValueImage from 'assets/images/about-us/our-value-640x400.jpeg';
import ourStyleImage from 'assets/images/about-us/our-style-640x400.jpeg';

const mock = [
  {
    title: 'Our Mission',
    subtitle:
      'Provide value for a new era by combining prototype using digital technology and realistic ideas.',
    image: ourMissionImage,
  },
  {
    title: 'Our Value',
    subtitle:
      'Co-creating value for a new era through internal and external collaboration and a prototyping-first style.',
    image: ourValueImage,
  },
  {
    title: 'Our Style',
    subtitle:
      'Skill sets of business and technology as well as high-demensional integartion of mindsets and styles for a new era.',
    image: ourStyleImage,
  },
];

const AbooutUs = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
          gutterBottom
          color={'secondary.main'}
          align={'center'}
        >
          {t('WHAT WE DO?')}
        </Typography>
        <Typography
          variant={'h3'}
          gutterBottom
          align={'center'}
          sx={{ fontWeight: 700 }}
        >
          {t('Inspire the digital with tangible prototypes')}
        </Typography>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'text.secondary'}
          align={'center'}
        >
          {t(
            'We work together with our clients to drive digital transformation by inspiring digital concepts with tangible prototypes, and by completing not only the digital conception, but also the digital implementation.',
          )}
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Box
                width={1}
                height={1}
                data-aos={'fade-up'}
                data-aos-delay={i * 100}
                data-aos-offset={100}
                data-aos-duration={600}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'flex-start'}
                >
                  <Box
                    component={LazyLoadImage}
                    height={1}
                    width={1}
                    src={item.image}
                    alt="..."
                    effect="blur"
                    sx={{
                      objectFit: 'cover',
                      maxHeight: 360,
                      borderRadius: 2,
                      filter:
                        theme.palette.mode === 'dark'
                          ? 'brightness(0.7)'
                          : 'none',
                    }}
                  />
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                  >
                    {t(item.title)}
                  </Typography>
                  <Typography color="text.secondary">
                    {t(item.subtitle)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AbooutUs;
