import React, { SVGProps } from 'react';
import type { FC } from 'react';

interface LogotypeProps extends SVGProps<SVGSVGElement> {
  green?: boolean;
}

const Logotype: FC<LogotypeProps> = ({ green, ...props }) => (
  <svg
    width="1131"
    height="192"
    viewBox="0 0 1131 192"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={green ? '#86bc25' : props.fill}
      d="M99.8264 34.8962C94.5085 16.8118 84.5455 0.76507 65.6953 0.76507C46.8451 0.76507 36.882 16.8118 31.5641 34.8962C24.014 60.5709 0 100.36 0 123.392C0 159.674 29.4128 189.087 65.6953 189.087C101.978 189.087 131.391 159.674 131.391 123.392C131.391 100.361 107.377 60.5712 99.8264 34.8962ZM65.6953 151.268C49.771 151.268 36.8622 141.46 36.8622 121.447C36.8622 101.434 49.771 77.6099 65.6953 77.6099C81.6195 77.6099 94.5287 101.435 94.5287 121.448C94.5287 141.46 81.6195 151.268 65.6953 151.268Z"
    />
    <path d="M208.743 191.47C198.326 191.47 190.735 185.997 185.968 175.051L145.979 82.891C142.977 76.0055 142.889 70.0028 145.714 64.8828C148.539 59.5862 154.365 56.9379 163.193 56.9379C167.96 56.9379 171.844 58.0855 174.845 60.3807C177.846 62.4993 180.495 66.6483 182.79 72.8276L210.067 141.683L238.139 72.5628C240.611 66.56 243.347 62.4993 246.348 60.3807C249.35 58.0855 253.499 56.9379 258.795 56.9379C265.504 56.9379 270.271 59.5862 273.096 64.8828C275.921 70.0028 275.744 75.9172 272.566 82.6262L231.783 175.051C227.193 185.997 219.513 191.47 208.743 191.47Z" />
    <path d="M354.52 192C340.573 192 328.391 189.263 317.974 183.79C307.734 178.141 299.789 170.284 294.14 160.221C288.49 149.981 285.665 137.887 285.665 123.939C285.665 110.168 288.49 98.251 294.14 88.1876C299.789 77.9476 307.734 70.091 317.974 64.6179C328.391 59.1448 340.573 56.4083 354.52 56.4083C368.468 56.4083 380.562 59.1448 390.802 64.6179C401.218 70.091 409.251 77.9476 414.901 88.1876C420.727 98.251 423.64 110.168 423.64 123.939C423.64 137.887 420.727 149.981 414.901 160.221C409.251 170.284 401.218 178.141 390.802 183.79C380.562 189.263 368.468 192 354.52 192ZM354.52 162.074C362.995 162.074 369.88 158.985 375.177 152.806C380.65 146.626 383.386 137.004 383.386 123.939C383.386 111.051 380.65 101.606 375.177 95.6028C369.88 89.4234 362.995 86.3338 354.52 86.3338C346.046 86.3338 339.16 89.4234 333.864 95.6028C328.567 101.606 325.919 111.051 325.919 123.939C325.919 137.004 328.567 146.626 333.864 152.806C339.16 158.985 346.046 162.074 354.52 162.074Z" />
    <path d="M511.263 192C497.139 192 484.78 189.263 474.187 183.79C463.771 178.141 455.737 170.196 450.088 159.956C444.438 149.716 441.613 137.622 441.613 123.674C441.613 109.727 444.438 97.7214 450.088 87.6579C455.737 77.5945 463.771 69.9145 474.187 64.6179C484.78 59.1448 497.139 56.4083 511.263 56.4083C516.736 56.4083 522.562 57.1145 528.742 58.5269C535.097 59.7628 541.188 61.9697 547.015 65.1476C551.428 67.2662 554.165 70.2676 555.224 74.1517C556.284 77.8593 556.019 81.5669 554.43 85.2745C553.017 88.9821 550.634 91.7186 547.279 93.4841C544.102 95.0731 540.306 94.72 535.892 92.4248C528.83 88.7172 521.944 86.8635 515.235 86.8635C504.819 86.8635 496.697 90.1297 490.871 96.6621C485.222 103.018 482.397 112.11 482.397 123.939C482.397 135.592 485.222 144.772 490.871 151.481C496.697 158.014 504.819 161.28 515.235 161.28C518.413 161.28 521.768 160.927 525.299 160.221C528.83 159.338 532.361 157.926 535.892 155.983C540.306 153.688 544.102 153.423 547.279 155.189C550.634 156.954 552.929 159.691 554.165 163.399C555.401 166.93 555.489 170.637 554.43 174.521C553.371 178.406 550.722 181.407 546.485 183.526C540.482 186.527 534.48 188.646 528.477 189.881C522.474 191.294 516.736 192 511.263 192Z" />
    <path d="M617.879 192C608.168 192 599.606 190.146 592.191 186.439C584.775 182.731 578.861 177.699 574.447 171.343C570.21 164.988 568.091 157.837 568.091 149.892C568.091 140.535 570.563 133.032 575.506 127.382C580.45 121.732 588.483 117.76 599.606 115.465C610.728 112.993 625.471 111.757 643.832 111.757H653.366V107.255C653.366 99.4869 651.6 94.0138 648.069 90.8359C644.538 87.4814 638.535 85.8041 630.061 85.8041C625.294 85.8041 620.086 86.4221 614.436 87.6579C608.786 88.7172 602.695 90.571 596.163 93.2193C591.219 95.3379 587.071 95.5145 583.716 93.749C580.362 91.8069 578.155 88.9821 577.095 85.2745C576.036 81.3903 576.301 77.5062 577.89 73.6221C579.655 69.7379 583.01 66.9131 587.953 65.1476C596.251 61.9697 604.019 59.7628 611.258 58.5269C618.673 57.1145 625.471 56.4083 631.65 56.4083C651.777 56.4083 666.784 60.9986 676.671 70.1793C686.557 79.1834 691.501 93.2193 691.501 112.287V171.079C691.501 184.673 685.322 191.47 672.963 191.47C660.428 191.47 654.16 184.673 654.16 171.079V169.49C651.512 176.375 647.01 181.848 640.654 185.909C634.475 189.97 626.883 192 617.879 192ZM626.883 165.252C634.298 165.252 640.566 162.692 645.686 157.572C650.806 152.452 653.366 145.832 653.366 137.71V132.414H644.097C630.502 132.414 620.88 133.65 615.231 136.121C609.581 138.417 606.756 142.477 606.756 148.303C606.756 153.247 608.433 157.308 611.788 160.486C615.319 163.663 620.351 165.252 626.883 165.252Z" />
    <path d="M771.913 192C760.614 192 750.639 189.263 741.988 183.79C733.513 178.141 726.893 170.284 722.126 160.221C717.359 149.981 714.975 137.887 714.975 123.939C714.975 109.992 717.359 97.9862 722.126 87.9228C726.893 77.8593 733.513 70.091 741.988 64.6179C750.639 59.1448 760.614 56.4083 771.913 56.4083C780.564 56.4083 788.509 58.2621 795.748 61.9697C802.986 65.6772 808.459 70.6207 812.167 76.8V20.3917C812.167 6.79724 818.788 0 832.029 0C845.447 0 852.156 6.79724 852.156 20.3917V171.079C852.156 184.673 845.535 191.47 832.294 191.47C819.053 191.47 812.432 184.673 812.432 171.079V170.284C808.901 176.993 803.428 182.29 796.013 186.174C788.774 190.058 780.741 192 771.913 192ZM783.83 162.074C792.305 162.074 799.191 158.985 804.487 152.806C809.96 146.626 812.697 137.004 812.697 123.939C812.697 111.051 809.96 101.606 804.487 95.6028C799.191 89.4234 792.305 86.3338 783.83 86.3338C775.356 86.3338 768.471 89.4234 763.174 95.6028C757.877 101.606 755.229 111.051 755.229 123.939C755.229 137.004 757.877 146.626 763.174 152.806C768.471 158.985 775.356 162.074 783.83 162.074Z" />
    <path d="M945.468 192C931.521 192 919.339 189.263 908.922 183.79C898.682 178.141 890.737 170.284 885.088 160.221C879.438 149.981 876.613 137.887 876.613 123.939C876.613 110.168 879.438 98.251 885.088 88.1876C890.737 77.9476 898.682 70.091 908.922 64.6179C919.339 59.1448 931.521 56.4083 945.468 56.4083C959.416 56.4083 971.51 59.1448 981.75 64.6179C992.166 70.091 1000.2 77.9476 1005.85 88.1876C1011.68 98.251 1014.59 110.168 1014.59 123.939C1014.59 137.887 1011.68 149.981 1005.85 160.221C1000.2 170.284 992.166 178.141 981.75 183.79C971.51 189.263 959.416 192 945.468 192ZM945.468 162.074C953.943 162.074 960.828 158.985 966.125 152.806C971.598 146.626 974.335 137.004 974.335 123.939C974.335 111.051 971.598 101.606 966.125 95.6028C960.828 89.4234 953.943 86.3338 945.468 86.3338C936.994 86.3338 930.108 89.4234 924.812 95.6028C919.515 101.606 916.867 111.051 916.867 123.939C916.867 137.004 919.515 146.626 924.812 152.806C930.108 158.985 936.994 162.074 945.468 162.074Z" />
    <path d="M1101.45 192C1064.9 192 1046.63 174.257 1046.63 138.77V88.9821H1035.51C1025.09 88.9821 1019.88 84.0386 1019.88 74.1517C1019.88 64.0883 1025.09 59.0566 1035.51 59.0566H1046.63V38.9297C1046.63 25.3352 1053.34 18.5379 1066.76 18.5379C1080 18.5379 1086.62 25.3352 1086.62 38.9297V59.0566H1111.78C1122.02 59.0566 1127.14 64.0883 1127.14 74.1517C1127.14 84.0386 1122.02 88.9821 1111.78 88.9821H1086.62V137.181C1086.62 144.596 1088.29 150.157 1091.65 153.865C1095.18 157.572 1100.74 159.426 1108.33 159.426C1110.98 159.426 1113.45 159.161 1115.75 158.632C1118.04 158.102 1120.16 157.749 1122.1 157.572C1124.58 157.396 1126.61 158.19 1128.2 159.956C1129.78 161.721 1130.58 165.341 1130.58 170.814C1130.58 175.228 1129.87 179.023 1128.46 182.201C1127.05 185.203 1124.58 187.41 1121.04 188.822C1118.75 189.705 1115.57 190.411 1111.51 190.941C1107.63 191.647 1104.27 192 1101.45 192Z" />
  </svg>
);

export default Logotype;
