import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from 'components/Container';
import {
  Topbar,
  Footer,
  ThemeModeToggler,
  Logout,
  TranslationToggler,
} from './components';

interface Props {
  children: React.ReactNode;
  colorInvert?: boolean;
  bgcolor?: string;
  onClick?: (id: string) => void;
  menuItem: { id: string; text: string }[];
}

const AvocadotMain = ({
  children,
  colorInvert = false,
  bgcolor = 'transparent',
  onClick = () => {},
  menuItem,
}: Props): JSX.Element => {
  const theme = useTheme();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  return (
    <Box>
      <Box bgcolor={bgcolor} position={'relative'} zIndex={theme.zIndex.appBar}>
        <Container paddingTop={'8px !important'} paddingBottom={'0 !important'}>
          <Box
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <Box>
              <ThemeModeToggler />
            </Box>
            <Box marginLeft={2}>
              <TranslationToggler />
            </Box>
            <Box marginLeft={2}>
              <Logout />
            </Box>
          </Box>
        </Container>
      </Box>
      <AppBar
        position={'sticky'}
        sx={{
          top: 0,
          backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
        }}
        elevation={trigger ? 1 : 0}
      >
        <Container paddingY={1}>
          <Topbar
            colorInvert={trigger ? false : colorInvert}
            onClick={onClick}
            menuItem={menuItem}
          />
        </Container>
      </AppBar>
      <main>{children}</main>
      <Container paddingY={4}>
        <Footer />
      </Container>
    </Box>
  );
};

export default AvocadotMain;
