import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import Page from './components/Page';
import 'App.css';
import './i18n';
import Amplify, { I18n } from 'aws-amplify';
import { Authenticator, translations } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import { Header, Footer, SignInHeader } from 'components/Authenticator';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
import 'aos/dist/aos.css';

const browserHistory = createBrowserHistory();

Amplify.configure(awsconfig);
I18n.putVocabularies(translations);
I18n.setLanguage('en');

const App = (): JSX.Element => {
  const components = {
    Header,
    Footer,
    SignIn: {
      Header: SignInHeader,
    },
  };

  /*
  const services = {
    async validateCustomSignUp(formData: Record<string, any>) {
      if (!(formData.email as string).endsWith('@tohmatsu.co.jp')) {
        return {
          email: 'Enter your @tohmatsu.co.jp email address',
        };
      }
    },
  };
  */

  return (
    <Authenticator components={components}>
      {() => (
        <Page>
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </Page>
      )}
    </Authenticator>
  );
};

export default App;
