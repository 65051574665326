/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ColorCardProps {
  name: string;
  variable: string;
  inversed?: boolean;
  bordered?: boolean;
}

const ColorCard: FC<ColorCardProps> = ({
  name,
  variable,
  inversed,
  bordered,
}): JSX.Element => {
  const ColorSpot = styled(Box)(() => ({
    padding: '8px',
    marginBottom: '8px',
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    height: '48px',
    // width: '160px',
    // borderRadius: '50%',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // marginTop: '20px',
    // marginBottom: '20px',
  }));

  const hex2rgb = (hex: string) => {
    hex = hex.trim();
    if (hex.slice(0, 1) === '#') hex = hex.slice(1);
    if (hex.length === 3)
      hex =
        hex.slice(0, 1) +
        hex.slice(0, 1) +
        hex.slice(1, 2) +
        hex.slice(1, 2) +
        hex.slice(2, 3) +
        hex.slice(2, 3);

    return [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map(function (
      str,
    ) {
      return parseInt(str, 16);
    });
  };

  const formatRGB = (rgb: number[]) => {
    if (rgb.length === 3) {
      return `R${rgb[0]} G${rgb[1]} B${rgb[2]}`;
    } else {
      return 'R G B';
    }
  };

  const borderWidth = bordered ? '1px' : '0px';

  return (
    <Box marginBottom={1}>
      <ColorSpot
        style={{
          backgroundColor: `var(${variable})`,
          border: `solid ${borderWidth} #d9d9d9`,
        }}
      >
        <Typography color={inversed ? 'white' : 'black'}>{name}</Typography>
      </ColorSpot>
      <Typography>
        {getComputedStyle(document.documentElement).getPropertyValue(variable)}
      </Typography>
      <Typography>
        {formatRGB(
          hex2rgb(
            getComputedStyle(document.documentElement).getPropertyValue(
              variable,
            ),
          ),
        )}
      </Typography>
    </Box>
  );
};

export default ColorCard;
