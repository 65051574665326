import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  id: string;
  colorInvert?: boolean;
  onClick?: (id: string) => void;
}

const NavItem = ({
  title,
  id,
  colorInvert = false,
  onClick = () => {},
}: Props): JSX.Element => {
  const linkColor = colorInvert ? 'common.white' : 'text.primary';
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        aria-describedby={id}
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => {
          onClick(id);
        }}
      >
        <Typography fontWeight={400} color={linkColor}>
          {t(title)}
        </Typography>
      </Box>
    </Box>
  );
};

export default NavItem;
