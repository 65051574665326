import React from 'react';
import Button from '@mui/material/Button';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { alpha, useTheme } from '@mui/material/styles';
import { Auth } from 'aws-amplify';

const Logout = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Button
      variant={'outlined'}
      onClick={() => Auth.signOut()}
      aria-label="Logout"
      color={'secondary'}
      sx={{
        borderRadius: 2,
        minWidth: 'auto',
        padding: 0.5,
        borderColor: alpha(theme.palette.secondary.main, 0.2),
      }}
    >
      <ExitToAppOutlinedIcon sx={{ width: 20, height: 20 }} />
    </Button>
  );
};

export default Logout;
