/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import type { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import ColorCard from './ColorCard';

interface Color {
  name: string;
  variable: string;
  bordered?: boolean;
  inversed?: boolean;
  xs?: any;
}

interface Pallete {
  name: string;
  colors: Array<Color>;
}

interface ColorThemeProps {
  columns: any;
  defaultXs: any;
  theme: Array<Pallete>;
}

const ColorTheme: FC<ColorThemeProps> = ({
  columns = 12,
  defaultXs,
  theme,
}): JSX.Element => {
  return (
    <Grid container columns={columns} spacing={2}>
      {theme.map((pallete: Pallete) => (
        <React.Fragment key={pallete.name}>
          <Grid item xs={columns} mt={2}>
            <Typography color="text.secondary" variant="h6">
              {pallete.name}
            </Typography>
          </Grid>
          {pallete.colors.map((color: Color) => (
            <Grid
              item
              xs={color.xs ?? defaultXs}
              key={pallete.name + '-' + color.name}
            >
              <ColorCard
                name={color.name}
                variable={color.variable}
                bordered={color?.bordered}
                inversed={color?.inversed}
              />
            </Grid>
          ))}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default ColorTheme;
